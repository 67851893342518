import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

import useLayoutApp from '@shared/hooks/useLayoutApp'
import { IS_GLANCE_MLIB } from '@shared/constants'

const OnMouseAndScroll = dynamic(() => import('@shared/components/lazyLoad/onMouseAndScroll'))
const StickyAds = dynamic(() => import('@shared/components/ads/sticky'))
const GoogleAnalytics = dynamic(() => import('@shared/components/scripts/googleAnalytics'))
const Glance = dynamic(() => import('@shared/components/ads/glance'))
const GlanceSticky = dynamic(() => import('@shared/components/ads/glanceSticky'))
const AppContent = dynamic(() => import('@shared/components/appContent'))
const GlanceMLib = dynamic(() => import('@shared/components/ads/glanceMLib'))
const MLibSticky = dynamic(() => import('@shared/components/ads/glanceMLib/mLibSticky'), { ssr: false })
const GEMAdCode = dynamic(() => import('@shared/components/ads/gemAdCode'))
const PopupMain = dynamic(() => import('../ads/popup-main'))
// const PopupAds = dynamic(() => import('../ads/popup'))

function AppLayout({ children }) {
  const { isAmp, isStaticPage, isGlanceView, isMobileWebView, isDHView, isGlanceLiveView, isWebStories } = useLayoutApp()

  const AddAdsScript = () => {
    // if (!isMobileWebView) {
    if (isGlanceView) {
      if (!IS_GLANCE_MLIB) {
        return (
          <>
            <Glance />
            <GlanceSticky />
          </>
        )
      } else return null
    } else if (!isAmp && !isDHView) {
      if (isMobileWebView) {
        return (
          <>
            <GEMAdCode />
          </>
        )
      } else {
        return (
          <OnMouseAndScroll>
            <GEMAdCode />
            {!isStaticPage && (
              <>
                <StickyAds />
                <PopupMain />
                {/* <PopupAds /> */}
              </>
            )}
          </OnMouseAndScroll>
        )
      }
    } else return null
    // } else return null
  }

  return (
    <>
      {!isMobileWebView && isGlanceView && IS_GLANCE_MLIB && (
        <>
          <GlanceMLib />
          {!isGlanceLiveView && (
            <OnMouseAndScroll>
              <MLibSticky />
            </OnMouseAndScroll>
          )}
        </>
      )}
      {!isMobileWebView && !isWebStories && <GoogleAnalytics />}
      {/* {!isMobileWebView && (
        <OnMouseAndScroll>
        </OnMouseAndScroll>
      )} */}
      <AppContent>{children}</AppContent>
      <AddAdsScript />
    </>
  )
}
AppLayout.propTypes = {
  children: PropTypes.node.isRequired
}
export default AppLayout
