import React from 'react'
import PropTypes from 'prop-types'
// import { useAmp } from 'next/amp'
import { useRouter } from 'next/router'

import { getHeaderSidebarMenu } from '@shared/libs/menu'
import {
  makeAuthorSchema,
  makeBreadcrumbSchema,
  makeLiveBlogSchema,
  makeMatchDetailArticleSchema,
  makeMatchDetailSchema,
  makeMediaOrganizationSchema,
  makeOrganizationSchema,
  makePersonSchema,
  makeSchema,
  makeSeriesSchema,
  makeSiteNavigationSchema,
  makeWebPageSchema,
  makeWebSiteSchema,
  makeWebStoriesSchema
} from '@shared/libs/schems'
import { getCurrentProps } from '@shared/libs/current-props'
import { DOMAIN, S3_PREFIX } from '@shared/constants'
import { convertDateToISTWithFormate, dateCheck } from '@shared/utils'

function AllSchema({ data, matchDetail, scoreCard = [], location }) {
  // const isAmp = useAmp()
  const { isWebStories } = getCurrentProps()
  const router = isWebStories ? location : useRouter() || location

  const [url] = router?.asPath?.split('?')
  const nav = url?.split('/')?.filter((x) => x)
  const sliderData = getHeaderSidebarMenu()
  const scoreCardData = scoreCard?.filter((s) => (s.sStatusStr === 'scheduled' || s.sStatusStr === 'live') && s)
  const articleSchema = makeSchema(data, nav)

  function getMatchDetailSchema() {
    function getScript(seo) {
      return (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(makeMatchDetailSchema(seo, matchDetail))
          }}
        />
      )
    }
    if (nav.includes('live-scores') && nav?.length === 2) {
      return matchDetail ? getScript(data?.oSeo) : null
    } else if (nav?.includes('full-scorecard')) {
      return matchDetail ? getScript({ ...data?.oSeo, sSlug: `${data?.oSeo?.sSlug}/full-scorecard` }) : null
    } else return null
  }

  return (
    <>
      {data?.oSeo?.eType === 'ar' && data?.iEventId && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(makeLiveBlogSchema(data, data.oLiveArticleEvent, data.oLiveArticleList)) }}
        />
      )}
      {data?.oSeo?.eType === 'ma' && nav.includes('live-scores') && nav?.length === 2 && (
        <>
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeMatchDetailArticleSchema(data)) }} />
        </>
      )}
      {articleSchema?.['@context'] && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(articleSchema) }} />
      )}
      {getMatchDetailSchema()}
      {scoreCardData?.length > 0 &&
        makeSeriesSchema(scoreCardData)?.map((j, i) => {
          return <script key={`match${i}`} type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(j) }} />
        })}
      {data?.oSeo?.eType === 'p' && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makePersonSchema(data)) }} />
      )}
      {data?.oSeo?.eType === 'ws' && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeWebStoriesSchema(data)) }} />
      )}
      {data?.oSeo?.eType === 'ad' && ( // author
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeAuthorSchema(data)) }} />
      )}

      {nav?.length > 0 && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeBreadcrumbSchema(nav, false)) }} />
      )}

      {data?.oSeo?.eType === 'au' && !data?.oSeo?.eSubType && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Event',
              name: `${data?.sTitle || data?.sName} Player Auction Live`,
              description: data?.oSeo?.sDescription,
              image: data?.oSeo?.oFB?.sUrl ? `${S3_PREFIX}${data?.oSeo?.oFB?.sUrl}` : 'https://www.crictracker.com/images/CricTracker-Facebook-Preview.jpg',
              startDate: convertDateToISTWithFormate(dateCheck(data?.oAuctionSeries?.dStartDate)),
              endDate: convertDateToISTWithFormate(dateCheck(data?.oAuctionSeries?.dEndDate)),
              eventStatus: 'https://schema.org/EventMovedOnline',
              eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
              location: {
                '@type': 'VirtualLocation',
                url: `${DOMAIN}${data?.oSeo?.sSlug}`
              }
            })
          }}
        />
      )}
      {/* Web page schema for all pages */}
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeWebPageSchema(data)) }} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(makeSiteNavigationSchema(sliderData, false)) }}
      />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeWebSiteSchema()) }} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeOrganizationSchema()) }} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(makeMediaOrganizationSchema()) }} />
    </>
  )
}
AllSchema.propTypes = {
  data: PropTypes.object,
  matchDetail: PropTypes.object,
  scoreCard: PropTypes.array,
  location: PropTypes.object
}
export default AllSchema
